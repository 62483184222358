export function DisabledPage() {
  return (
    <div className="h-screen w-full grid items-center justify-items-center">
      <div className="max-w-lg p-4">
        <div className="text-2xl font-bold font-display mb-2 text-[#e501ff]">Uh oh.</div>
        <div className="mb-2">Your account is currently disabled. Please contact your supervisor or the person who sent you here.</div>
      </div>
    </div>
  );
}
