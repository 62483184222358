import {
  HyposmiaSeverity,
  ImplantableCardiacDeviceType,
  PriorRespiratorUseProblemCause,
  RespiratorType,
  RespiratoryHazard,
  Severity,
  TimeFrame,
  WorkEnvironment,
} from './lib/general';
import { gte, no, unknown, yes } from './lib/validation';
import { JobRole } from './types/api';
import { Schema } from './types/schema';

const highlyHazardous = (jobRole: JobRole) => (
  jobRole.modules.respiratorMedicalEvaluation !== undefined
  && jobRole.modules.respiratorMedicalEvaluation.respiratorTypeUsages.some(
    ({ idlhEnvironment, respiratoryHazards, workEnvironments }) => (
      idlhEnvironment
      || respiratoryHazards.includes(RespiratoryHazard.OXYGEN_DEFICIENT)
      || respiratoryHazards.includes(RespiratoryHazard.OXYGEN_ENRICHED)
      || workEnvironments.includes(WorkEnvironment.CONFINED_SPACE)
    ),
  )
);

const fullFaceEvaluated = (jobRole: JobRole) => (
  jobRole.modules.respiratorMedicalEvaluation !== undefined
  && jobRole.modules.respiratorMedicalEvaluation.respiratorTypeUsages.some(
    ({ respiratorType }) => (
      respiratorType === RespiratorType.AIR_PURIFYING_REUSABLE_FULL_FACE
      || respiratorType === RespiratorType.POWERED_AIR_PURIFYING_FULL_FACE
      || respiratorType === RespiratorType.SUPPLIED_AIR_HOSE_FULL_FACE
      || respiratorType === RespiratorType.SUPPLIED_AIR_SCBA
    ),
  )
);

const sarEvaluated = (jobRole: JobRole) => (
  jobRole.modules.respiratorMedicalEvaluation !== undefined
  && jobRole.modules.respiratorMedicalEvaluation.respiratorTypeUsages.some(
    ({ respiratorType }) => (
      respiratorType === RespiratorType.SUPPLIED_AIR_HOSE_FULL_FACE
      || respiratorType === RespiratorType.SUPPLIED_AIR_HOSE_PARTIAL_FACE
      || respiratorType === RespiratorType.SUPPLIED_AIR_SCBA
    ),
  )
);

export const schema: Schema = {
  questions: {
    allergies: {
      type: 'ENUM',
    },
    allergiesAnaphylaxis: {
      required: (r) => yes(r.allergies),
      type: 'ENUM',
    },
    allergiesAtWorkEasilyTreatable: {
      required: (r) => (
        (
          no(r.allergiesHadAtWork)
          || unknown(r.allergiesTriggersAtWork)
        )
        && (
          yes(r.allergiesMedications)
          || yes(r.allergiesPrescribedEpipen)
        )
      ),
      type: 'ENUM',
    },
    allergiesHadAtWork: {
      required: (r) => no(r.allergiesKnowsTriggers),
      type: 'ENUM',
    },
    allergiesKnowsTriggers: {
      required: (r) => (
        yes(r.allergiesAnaphylaxis)
        || yes(r.allergiesPrescribedEpipen)
      ),
      type: 'ENUM',
    },
    allergiesMakesRespiratorUseUnsafe: {
      required: (r) => yes(r.allergies),
      type: 'ENUM',
    },
    allergiesMedications: {
      required: (r) => yes(r.allergies),
      type: 'ENUM',
    },
    allergiesMedicationsAdverseEffects: {
      required: (r) => yes(r.allergiesMedications),
      type: 'ENUM',
    },
    allergiesPrescribedEpipen: {
      required: (r) => yes(r.allergies),
      type: 'ENUM',
    },
    allergiesTriggersAtWork: {
      required: (r) => yes(r.allergiesKnowsTriggers),
      type: 'ENUM',
    },
    angina: {
      type: 'ENUM',
    },
    anginaAssessedByDoctor: {
      required: (r) => (
        yes(r.angina)
        || yes(r.chestPainDuringPhysicalActivity)
        || yes(r.chestPainFrequent)
        || yes(r.chestPainInterferingWithWork)
      ),
      type: 'ENUM',
    },
    anginaCardiac: {
      required: (r) => yes(r.anginaAssessedByDoctor),
      type: 'ENUM',
    },
    anginaControlled: {
      required: (r) => (
        yes(r.anginaCardiac)
        || (
          no(r.anginaCardiac)
          && yes(r.heartAttack)
        )
      ),
      type: 'ENUM',
    },
    anginaLastYear: {
      required: (r) => (
        no(r.anginaAssessedByDoctor)
        || yes(r.anginaCardiac)
        || (
          no(r.anginaCardiac)
          && yes(r.heartAttack)
        )
      ),
      type: 'ENUM',
    },
    anginaPredictable: {
      required: (r) => (
        no(r.anginaAssessedByDoctor)
        || yes(r.anginaCardiac)
        || (
          no(r.anginaCardiac)
          && yes(r.heartAttack)
        )
      ),
      type: 'ENUM',
    },
    anginaRescueMedUseLastYear: {
      required: (r) => (
        yes(r.heartAttack)
        || no(r.anginaAssessedByDoctor)
        || yes(r.anginaCardiac)
        || yes(r.heartFailure)
        || yes(r.peripheralEdemaCurrent)
      ),
      type: 'ENUM',
    },
    anginaRestingOrDuringLightActivity: {
      required: (r) => (
        no(r.anginaAssessedByDoctor)
        || yes(r.anginaCardiac)
        || (
          no(r.anginaCardiac)
          && yes(r.heartAttack)
        )
      ),
      type: 'ENUM',
    },
    anginaWorkAccommodations: {
      required: (r) => (
        no(r.anginaAssessedByDoctor)
        || yes(r.anginaCardiac)
        || (
          no(r.anginaCardiac)
          && yes(r.heartAttack)
        )
      ),
      type: 'ENUM',
    },
    arrhythmia: {
      type: 'ENUM',
    },
    arrhythmiaOtherThanHeartMurmur: {
      required: (r) => (
        (
          yes(r.arrhythmia)
          || yes(r.skippedHeartbeatLastTwoYears)
        )
        && yes(r.heartMurmur)
      ),
      type: 'ENUM',
    },
    arrhythmiaTypes: {
      required: (r) => (
        (
          (
            yes(r.arrhythmia)
            || yes(r.skippedHeartbeatLastTwoYears)
          )
          && no(r.heartMurmur)
        )
        || yes(r.arrhythmiaOtherThanHeartMurmur)
      ),
      type: 'ARRAY',
    },
    arrhythmiaWorkAccommodations: {
      required: (r) => (
        (
          (
            yes(r.arrhythmia)
            || yes(r.skippedHeartbeatLastTwoYears)
          )
          && no(r.heartMurmur)
        )
        || yes(r.arrhythmiaOtherThanHeartMurmur)
      ),
      type: 'ENUM',
    },
    asbestosis: {
      type: 'ENUM',
    },
    asbestosisFutureRespiratorUseExpectedNotOk: {
      required: (r) => (
        yes(r.asbestosis)
        && no(r.priorRespiratorUse)
      ),
      type: 'ENUM',
    },
    asbestosisHospitalStayLast2Years: {
      required: (r) => (
        r.asbestosisSeverity === Severity.MODERATE
        || r.asbestosisSeverity === Severity.SEVERE
      ),
      type: 'ENUM',
    },
    asbestosisImpactedWorkLast2Years: {
      required: (r) => (
        r.asbestosisSeverity === Severity.MODERATE
        || r.asbestosisSeverity === Severity.SEVERE
      ),
      type: 'ENUM',
    },
    asbestosisMedications: {
      required: (r) => yes(r.asbestosis),
      type: 'ENUM',
    },
    asbestosisMedicationsAccessibleAtWork: {
      required: (r) => yes(r.asbestosisMedications),
      type: 'ENUM',
    },
    asbestosisPriorRespiratorUseNotOk: {
      required: (r) => (
        yes(r.asbestosis)
        && yes(r.priorRespiratorUse)
      ),
      type: 'ENUM',
    },
    asbestosisSeverity: {
      required: (r) => yes(r.asbestosis),
      type: 'ENUM',
    },
    asbestosisSxDuringAdl: {
      required: (r) => yes(r.asbestosis),
      type: 'ENUM',
    },
    asbestosisWorkAccommodations: {
      required: (r) => yes(r.asbestosis),
      type: 'ENUM',
    },
    asbestosisWorseLastYear: {
      required: (r) => yes(r.asbestosis),
      type: 'ENUM',
    },
    asthma: {
      type: 'ENUM',
    },
    asthmaAggravatorsAtWork: {
      required: (r) => yes(r.asthmaCurrent),
      type: 'ENUM',
    },
    asthmaCurrent: {
      required: (r) => yes(r.asthma),
      type: 'ENUM',
    },
    asthmaFutureRespiratorUseExpectedNotOk: {
      required: (r) => (
        yes(r.asthmaCurrent)
        && no(r.priorRespiratorUse)
      ),
      type: 'ENUM',
    },
    asthmaHospitalStayLast2Years: {
      required: (r) => (
        r.asthmaSeverity === Severity.MODERATE
        || r.asthmaSeverity === Severity.SEVERE
      ),
      type: 'ENUM',
    },
    asthmaImpactedWorkLast2Years: {
      required: (r) => (
        r.asthmaSeverity === Severity.MODERATE
        || r.asthmaSeverity === Severity.SEVERE
      ),
      type: 'ENUM',
    },
    asthmaMedications: {
      required: (r) => yes(r.asthmaCurrent),
      type: 'ENUM',
    },
    asthmaMedicationsAccessibleAtWork: {
      required: (r) => yes(r.asthmaMedications),
      type: 'ENUM',
    },
    asthmaPriorRespiratorUseNotOk: {
      required: (r) => (
        yes(r.asthmaCurrent)
        && yes(r.priorRespiratorUse)
      ),
      type: 'ENUM',
    },
    asthmaSeverity: {
      required: (r) => yes(r.asthmaCurrent),
      type: 'ENUM',
    },
    asthmaSxDuringAdl: {
      required: (r) => yes(r.asthmaCurrent),
      type: 'ENUM',
    },
    asthmaWorkAccommodations: {
      required: (r) => yes(r.asthmaCurrent),
      type: 'ENUM',
    },
    asthmaWorseLastYear: {
      required: (r) => yes(r.asthmaCurrent),
      type: 'ENUM',
    },
    backInjury: {
      required: (_, j) => fullFaceEvaluated(j),
      type: 'ENUM',
    },
    backInjuryResolved: {
      required: (r) => yes(r.backInjury),
      type: 'ENUM',
    },
    backPain: {
      required: (_, j) => fullFaceEvaluated(j),
      type: 'ENUM',
    },
    backPainPredictsRespiratorWillExacerbate: {
      required: (r) => (
        no(r.backInjuryResolved)
        || yes(r.backPain)
      ),
      type: 'ENUM',
    },
    brokenEardrum: {
      required: (_, j) => fullFaceEvaluated(j),
      type: 'ENUM',
    },
    brokenEardrumResolved: {
      required: (r) => yes(r.brokenEardrum),
      type: 'ENUM',
    },
    brokenRibs: {
      type: 'ENUM',
    },
    brokenRibsCanExerciseWithoutSob: {
      required: (r) => no(r.brokenRibsResolved),
      type: 'ENUM',
    },
    brokenRibsCanPerformWorkFully: {
      required: (r) => no(r.brokenRibsResolved),
      type: 'ENUM',
    },
    brokenRibsResolved: {
      required: (r) => yes(r.brokenRibs),
      type: 'ENUM',
    },
    chestPainBreathingDeeply: {
      type: 'ENUM',
    },
    chestPainDuringPhysicalActivity: {
      type: 'ENUM',
    },
    chestPainFrequent: {
      type: 'ENUM',
    },
    chestPainInterferingWithWork: {
      type: 'ENUM',
    },
    chronicBronchitis: {
      type: 'ENUM',
    },
    chronicBronchitisFutureRespiratorUseExpectedNotOk: {
      required: (r) => (
        yes(r.chronicBronchitis)
        && no(r.priorRespiratorUse)
      ),
      type: 'ENUM',
    },
    chronicBronchitisHospitalStayLast2Years: {
      required: (r) => (
        r.chronicBronchitisSeverity === Severity.MODERATE
        || r.chronicBronchitisSeverity === Severity.SEVERE
      ),
      type: 'ENUM',
    },
    chronicBronchitisImpactedWorkLast2Years: {
      required: (r) => (
        r.chronicBronchitisSeverity === Severity.MODERATE
        || r.chronicBronchitisSeverity === Severity.SEVERE
      ),
      type: 'ENUM',
    },
    chronicBronchitisMedications: {
      required: (r) => yes(r.chronicBronchitis),
      type: 'ENUM',
    },
    chronicBronchitisMedicationsAccessibleAtWork: {
      required: (r) => yes(r.chronicBronchitisMedications),
      type: 'ENUM',
    },
    chronicBronchitisPriorRespiratorUseNotOk: {
      required: (r) => (
        yes(r.chronicBronchitis)
        && yes(r.priorRespiratorUse)
      ),
      type: 'ENUM',
    },
    chronicBronchitisSeverity: {
      required: (r) => yes(r.chronicBronchitis),
      type: 'ENUM',
    },
    chronicBronchitisSxDuringAdl: {
      required: (r) => yes(r.chronicBronchitis),
      type: 'ENUM',
    },
    chronicBronchitisWorkAccommodations: {
      required: (r) => yes(r.chronicBronchitis),
      type: 'ENUM',
    },
    chronicBronchitisWorseLastYear: {
      required: (r) => yes(r.chronicBronchitis),
      type: 'ENUM',
    },
    claustrophobia: {
      type: 'ENUM',
    },
    claustrophobiaCurrent: {
      required: (r) => yes(r.claustrophobia),
      type: 'ENUM',
    },
    claustrophobiaFutureRespiratorUseExpectedOk: {
      required: (r) => (
        (
          yes(r.claustrophobiaCurrent)
          && no(r.priorRespiratorUse)
        )
        || yes(r.claustrophobiaPriorRespiratorUseOk)
      ),
      type: 'ENUM',
    },
    claustrophobiaMedications: {
      required: (r) => yes(r.claustrophobiaCurrent),
      type: 'ENUM',
    },
    claustrophobiaMedicationsAdverseEffects: {
      required: (r) => yes(r.claustrophobiaMedications),
      type: 'ENUM',
    },
    claustrophobiaPriorRespiratorUseOk: {
      required: (r) => (
        yes(r.claustrophobiaCurrent)
        && yes(r.priorRespiratorUse)
      ),
      type: 'ENUM',
    },
    colorBlindness: {
      required: (_, j) => fullFaceEvaluated(j),
      type: 'ENUM',
    },
    colorBlindnessHasTroubleSeeingWarnings: {
      required: (r) => yes(r.colorBlindness),
      type: 'ENUM',
    },
    colorBlindnessWillHaveHelpSelectingColorCodedParts: {
      required: (r) => yes(r.colorBlindness),
      type: 'ENUM',
    },
    coughBloodyInLastMonth: {
      type: 'ENUM',
    },
    coughFitsDifficultToBreatheMoreThan10Seconds: {
      required: (r) => (
        yes(r.coughBloodyInLastMonth)
        || yes(r.coughProducingPhlegm)
        || yes(r.coughWakingEarly)
        || yes(r.coughWhenLying)
      ),
      type: 'ENUM',
    },
    coughMedicationAccessibleAtWork: {
      required: (r) => yes(r.coughMedicationRequiredForFits),
      type: 'ENUM',
    },
    coughMedicationRequiredForFits: {
      required: (r) => (
        yes(r.coughBloodyInLastMonth)
        || yes(r.coughProducingPhlegm)
        || yes(r.coughWakingEarly)
        || yes(r.coughWhenLying)
      ),
      type: 'ENUM',
    },
    coughPredictsDifficultyUsingRespirator: {
      required: (r) => (
        yes(r.coughBloodyInLastMonth)
        || yes(r.coughProducingPhlegm)
        || yes(r.coughWakingEarly)
        || yes(r.coughWhenLying)
      ),
      type: 'ENUM',
    },
    coughProducingPhlegm: {
      type: 'ENUM',
    },
    coughSeverity: {
      required: (r) => (
        yes(r.coughBloodyInLastMonth)
        || yes(r.coughProducingPhlegm)
        || yes(r.coughWakingEarly)
        || yes(r.coughWhenLying)
      ),
      type: 'ENUM',
    },
    coughWakingEarly: {
      type: 'ENUM',
    },
    coughWhenLying: {
      type: 'ENUM',
    },
    diabetes: {
      type: 'ENUM',
    },
    diabetesCurrent: {
      required: (r) => yes(r.diabetes),
      type: 'ENUM',
    },
    diabetesMedications: {
      required: (r) => yes(r.diabetesCurrent),
      type: 'ENUM',
    },
    diabetesSyncopeAtWork: {
      required: (r) => yes(r.diabetesMedications),
      type: 'ENUM',
    },
    diabetesWarningSigns: {
      required: (r) => yes(r.diabetesMedications),
      type: 'ENUM',
    },
    diabetesWorkAccommodations: {
      required: (r) => yes(r.diabetesCurrent),
      type: 'ENUM',
    },
    difficultyBendingKnees: {
      required: (_, j) => fullFaceEvaluated(j),
      type: 'ENUM',
    },
    difficultyClimbingStairs25Pounds: {
      required: (_, j) => fullFaceEvaluated(j),
      type: 'ENUM',
    },
    difficultyMovingHeadHorizontally: {
      required: (_, j) => fullFaceEvaluated(j),
      type: 'ENUM',
    },
    difficultyMovingExtremities: {
      required: (_, j) => fullFaceEvaluated(j),
      type: 'ENUM',
    },
    difficultyMovingHeadVertically: {
      required: (_, j) => fullFaceEvaluated(j),
      type: 'ENUM',
    },
    difficultyMovingPredictsRespiratorWillExacerbate: {
      required: (r) => (
        yes(r.difficultyMovingExtremities)
        || yes(r.painStiffnessBendingWaist)
        || yes(r.difficultyMovingHeadHorizontally)
        || yes(r.difficultyMovingHeadVertically)
        || yes(r.difficultyBendingKnees)
        || yes(r.difficultySquatting)
      ),
      type: 'ENUM',
    },
    difficultyMovingSeverity: {
      required: (r) => (
        yes(r.difficultyMovingExtremities)
        || yes(r.painStiffnessBendingWaist)
        || yes(r.difficultyMovingHeadHorizontally)
        || yes(r.difficultyMovingHeadVertically)
        || yes(r.difficultyBendingKnees)
        || yes(r.difficultySquatting)
      ),
      type: 'ENUM',
    },
    difficultySquatting: {
      required: (_, j) => fullFaceEvaluated(j),
      type: 'ENUM',
    },
    emphysema: {
      type: 'ENUM',
    },
    emphysemaFutureRespiratorUseExpectedNotOk: {
      required: (r) => (
        yes(r.emphysema)
        && no(r.priorRespiratorUse)
      ),
      type: 'ENUM',
    },
    emphysemaHospitalStayLast2Years: {
      required: (r) => (
        r.emphysemaSeverity === Severity.MODERATE
        || r.emphysemaSeverity === Severity.SEVERE
      ),
      type: 'ENUM',
    },
    emphysemaImpactedWorkLast2Years: {
      required: (r) => (
        r.emphysemaSeverity === Severity.MODERATE
        || r.emphysemaSeverity === Severity.SEVERE
      ),
      type: 'ENUM',
    },
    emphysemaMedications: {
      required: (r) => yes(r.emphysema),
      type: 'ENUM',
    },
    emphysemaMedicationsAccessibleAtWork: {
      required: (r) => yes(r.emphysemaMedications),
      type: 'ENUM',
    },
    emphysemaPriorRespiratorUseNotOk: {
      required: (r) => (
        yes(r.emphysema)
        && yes(r.priorRespiratorUse)
      ),
      type: 'ENUM',
    },
    emphysemaSeverity: {
      required: (r) => yes(r.emphysema),
      type: 'ENUM',
    },
    emphysemaSxDuringAdl: {
      required: (r) => yes(r.emphysema),
      type: 'ENUM',
    },
    emphysemaWorkAccommodations: {
      required: (r) => yes(r.emphysema),
      type: 'ENUM',
    },
    emphysemaWorseLastYear: {
      required: (r) => yes(r.emphysema),
      type: 'ENUM',
    },
    genderIdentity: {
      required: () => false,
      type: 'ENUM',
    },
    hearingAids: {
      required: (_, j) => fullFaceEvaluated(j),
      type: 'ENUM',
    },
    hearingAidsCanUseAtWork: {
      required: (r) => yes(r.hearingAidsNeedsForWork),
      type: 'ENUM',
    },
    hearingAidsNeedsForWork: {
      required: (r) => yes(r.hearingAids),
      type: 'ENUM',
    },
    hearingLoss: {
      required: (_, j) => fullFaceEvaluated(j),
      type: 'ENUM',
    },
    hearingLossCanPerformWorkSafely: {
      required: (r) => (
        yes(r.hearingLoss)
        || no(r.brokenEardrumResolved)
        || yes(r.miscEarInjuries)
      ),
      type: 'ENUM',
    },
    heartAttack: {
      type: 'ENUM',
    },
    heartAttackCardiacHospitalVisitLastYear: {
      required: (r) => yes(r.heartAttack),
      type: 'ENUM',
    },
    heartAttackCoronaryArteryProcedureLast6Months: {
      required: (r) => yes(r.heartAttack),
      type: 'ENUM',
    },
    heartAttackHadStressTest: {
      required: (r) => yes(r.heartAttack),
      type: 'ENUM',
    },
    heartAttackLessThan3YearsAgo: {
      required: (r) => yes(r.heartAttack),
      type: 'ENUM',
    },
    heartAttackRestrictionsRemoved: {
      required: (r) => yes(r.heartAttack),
      type: 'ENUM',
    },
    heartAttackStressTestFailed: {
      required: (r) => yes(r.heartAttackHadStressTest),
      type: 'ENUM',
    },
    heartAttackStressTestShowedArrhythmiaOrIschemia: {
      required: (r) => yes(r.heartAttackHadStressTest),
      type: 'ENUM',
    },
    heartAttackWorkAccommodations: {
      required: (r) => yes(r.heartAttack),
      type: 'ENUM',
    },
    heartCondition150MinutesActivityPerWeek: {
      required: (r) => (
        yes(r.heartAttack)
        || no(r.anginaAssessedByDoctor)
        || yes(r.anginaCardiac)
        || yes(r.heartFailure)
        || yes(r.peripheralEdemaCurrent)
        || yes(r.hypertensionCurrent)
        || yes(r.hypertensionMedications)
      ),
      type: 'ENUM',
    },
    heartConditionAdditionalSxLast2Years: {
      required: (r) => (
        yes(r.heartAttack)
        || no(r.anginaAssessedByDoctor)
        || yes(r.anginaCardiac)
        || yes(r.heartFailure)
        || yes(r.peripheralEdemaCurrent)
      ),
      type: 'ENUM',
    },
    heartConditionHighCholesterol: {
      required: (r) => (
        yes(r.heartAttack)
        || no(r.anginaAssessedByDoctor)
        || yes(r.anginaCardiac)
        || yes(r.heartFailure)
        || yes(r.peripheralEdemaCurrent)
        || yes(r.hypertensionCurrent)
        || yes(r.hypertensionMedications)
      ),
      type: 'ENUM',
    },
    heartConditionRespiratorUseExpectedOk: {
      required: (r) => (
        yes(r.heartAttack)
        || yes(r.anginaCardiac)
        || yes(r.heartFailure)
      ),
      type: 'ENUM',
    },
    heartFailure: {
      type: 'ENUM',
    },
    heartFailureFunctionalClassification: {
      required: (r) => (
        yes(r.heartFailure)
        || yes(r.peripheralEdemaCurrent)
      ),
      type: 'ENUM',
    },
    heartFailureWorkAccommodations: {
      required: (r) => yes(r.heartFailure),
      type: 'ENUM',
    },
    heartMurmur: {
      type: 'ENUM',
    },
    heartMurmurAdditionalSx: {
      required: (r) => yes(r.heartMurmur),
      type: 'ENUM',
    },
    heartMurmurCheckedByDoctor: {
      required: (r) => yes(r.heartMurmur),
      type: 'ENUM',
    },
    heartMurmurClearedByDoctor: {
      required: (r) => yes(r.heartMurmurCheckedByDoctor),
      type: 'ENUM',
    },
    heartburn: {
      type: 'ENUM',
    },
    heartburnCardiac: {
      required: (r) => (
        no(r.heartburnRelievedByAntacids)
        || unknown(r.heartburnRelievedByAntacids)
      ),
      type: 'ENUM',
    },
    heartburnRelievedByAntacids: {
      required: (r) => yes(r.heartburn),
      type: 'ENUM',
    },
    height: {
      type: 'NUMBER',
    },
    hypertension: {
      type: 'ENUM',
    },
    hypertensionCausedProblemsAtWork: {
      required: (r) => (
        yes(r.hypertensionCurrent)
        || yes(r.hypertensionMedications)
      ),
      type: 'ENUM',
    },
    hypertensionCurrent: {
      required: (r) => yes(r.hypertension),
      type: 'ENUM',
    },
    hypertensionDoctorVisitLastYear: {
      required: (r) => (
        gte(r.hypertensionTypicalBpSystolic, 141)
        || gte(r.hypertensionTypicalBpDiastolic, 91)
      ),
      type: 'ENUM',
    },
    hypertensionMedications: {
      type: 'ENUM',
    },
    hypertensionMedicationsAdverseEffects: {
      required: (r) => yes(r.hypertensionMedications),
      type: 'ENUM',
    },
    hypertensionRestrictions: {
      required: (r) => (
        yes(r.hypertensionCurrent)
        || yes(r.hypertensionMedications)
      ),
      type: 'ENUM',
    },
    hypertensionTypicalBpDiastolic: {
      required: (r) => (
        yes(r.hypertensionCurrent)
        || yes(r.hypertensionMedications)
      ),
      type: 'NUMBER',
    },
    hypertensionTypicalBpSystolic: {
      required: (r) => (
        yes(r.hypertensionCurrent)
        || yes(r.hypertensionMedications)
      ),
      type: 'NUMBER',
    },
    hypertensionWorriesAboutWork: {
      required: (r) => (
        yes(r.hypertensionCurrent)
        || yes(r.hypertensionMedications)
      ),
      type: 'ENUM',
    },
    hyposmia: {
      type: 'ENUM',
    },
    hyposmiaCanSmellWorkDangers: {
      required: (r) => (
        r.hyposmiaSeverity === HyposmiaSeverity.SMELL_NOXIOUS_THINGS_ONLY
        && yes(r.hyposmiaNeedsSmellForWork)
      ),
      type: 'ENUM',
    },
    hyposmiaCurrent: {
      required: (r) => yes(r.hyposmia),
      type: 'ENUM',
    },
    hyposmiaNeedsSmellForWork: {
      required: (r) => (
        r.hyposmiaSeverity === HyposmiaSeverity.SMELL_NOXIOUS_THINGS_ONLY
        || r.hyposmiaSeverity === HyposmiaSeverity.ANOSMIA
      ),
      type: 'ENUM',
    },
    hyposmiaSeverity: {
      required: (r) => yes(r.hyposmiaCurrent),
      type: 'ENUM',
    },
    implantableCardiacDevice: {
      required: (r) => (
        (
          (
            yes(r.arrhythmia)
            || yes(r.skippedHeartbeatLastTwoYears)
          )
          && no(r.heartMurmur)
        )
        || yes(r.arrhythmiaOtherThanHeartMurmur)
        || yes(r.heartFailure)
        || yes(r.peripheralEdema)
      ),
      type: 'ENUM',
    },
    implantableCardiacDeviceFixedArrhythmia: {
      required: (r) => (
        (
          (
            (
              yes(r.arrhythmia)
              || yes(r.skippedHeartbeatLastTwoYears)
            )
            && no(r.heartMurmur)
          )
          || yes(r.arrhythmiaOtherThanHeartMurmur)
          || yes(r.skippedHeartbeatLastTwoYears)
        )
        && yes(r.implantableCardiacDevice)
      ),
      type: 'ENUM',
    },
    implantableCardiacDeviceType: {
      required: (r) => yes(r.implantableCardiacDevice),
      type: 'ENUM',
    },
    implantableCardiacDeviceWorksAroundStrongMagneticFields: {
      required: (r) => r.implantableCardiacDeviceType === ImplantableCardiacDeviceType.AICD,
      type: 'ENUM',
    },
    implantableCardiacDeviceWorksWithPossibilityOfCollisions: {
      required: (r) => r.implantableCardiacDeviceType === ImplantableCardiacDeviceType.PACEMAKER,
      type: 'ENUM',
    },
    lungCancer: {
      type: 'ENUM',
    },
    lungCancerCurrentProblemsMakeRespiratorUseDifficult: {
      required: (r) => yes(r.lungCancer),
      type: 'ENUM',
    },
    lungCancerInRemission: {
      required: (r) => yes(r.lungCancer),
      type: 'ENUM',
    },
    lungCancerInRemissionAtLeastFiveYears: {
      required: (r) => yes(r.lungCancerInRemission),
      type: 'ENUM',
    },
    lungCancerProblemsWithSar: {
      required: (r) => yes(r.lungCancerUsedSarAtWorkSinceRemission),
      type: 'ENUM',
    },
    lungCancerUsedSarAtWorkSinceRemission: {
      required: (r, j) => (
        yes(r.lungCancerInRemissionAtLeastFiveYears)
        && sarEvaluated(j)
        && yes(r.priorRespiratorUse)
      ),
      type: 'ENUM',
    },
    lungCancerWorkAccommodations: {
      required: (r) => yes(r.lungCancer),
      type: 'ENUM',
    },
    miscChestInjuriesOrSurgeries: {
      type: 'ENUM',
    },
    miscChestInjuriesOrSurgeriesCanExerciseWithoutSob: {
      required: (r) => no(r.miscChestInjuriesOrSurgeriesResolved),
      type: 'ENUM',
    },
    miscChestInjuriesOrSurgeriesCanPerformWorkFully: {
      required: (r) => no(r.miscChestInjuriesOrSurgeriesResolved),
      type: 'ENUM',
    },
    miscChestInjuriesOrSurgeriesResolved: {
      required: (r) => yes(r.miscChestInjuriesOrSurgeries),
      type: 'ENUM',
    },
    miscEarInjuries: {
      required: (_, j) => fullFaceEvaluated(j),
      type: 'ENUM',
    },
    miscHearingProblems: {
      required: (_, j) => fullFaceEvaluated(j),
      type: 'ENUM',
    },
    miscHearingProblemsDescription: {
      required: (r) => yes(r.miscHearingProblems),
      type: 'STRING',
    },
    miscHeartMedications: {
      type: 'ENUM',
    },
    miscHeartMedicationsDescription: {
      required: (r) => yes(r.miscHeartMedications),
      type: 'STRING',
    },
    miscHeartProblems: {
      type: 'ENUM',
    },
    miscHeartProblemsDescription: {
      required: (r) => yes(r.miscHeartProblems),
      type: 'STRING',
    },
    miscHeartSymptoms: {
      type: 'ENUM',
    },
    miscHeartSymptomsDescription: {
      required: (r) => yes(r.miscHeartSymptoms),
      type: 'STRING',
    },
    miscLungMedications: {
      type: 'ENUM',
    },
    miscLungMedicationsDescription: {
      required: (r) => yes(r.miscLungMedications),
      type: 'STRING',
    },
    miscLungProblems: {
      type: 'ENUM',
    },
    miscLungProblemsDescription: {
      required: (r) => yes(r.miscLungProblems),
      type: 'STRING',
    },
    miscLungSymptoms: {
      type: 'ENUM',
    },
    miscLungSymptomsDescription: {
      required: (r) => yes(r.miscLungSymptoms),
      type: 'STRING',
    },
    miscMedicationsAdverseEffects: {
      required: (r) => (
        yes(r.miscLungMedications)
        || yes(r.miscHeartMedications)
      ),
      type: 'ENUM',
    },
    miscMusculoskeletalProblems: {
      required: (_, j) => fullFaceEvaluated(j),
      type: 'ENUM',
    },
    miscMusculoskeletalProblemsDescription: {
      required: (r) => yes(r.miscMusculoskeletalProblems),
      type: 'STRING',
    },
    miscVisionProblems: {
      required: (_, j) => fullFaceEvaluated(j),
      type: 'ENUM',
    },
    miscVisionProblemsDescription: {
      required: (r) => yes(r.miscVisionProblems),
      type: 'STRING',
    },
    painStiffnessBendingWaist: {
      required: (_, j) => fullFaceEvaluated(j),
      type: 'ENUM',
    },
    peripheralEdema: {
      type: 'ENUM',
    },
    peripheralEdemaCurrent: {
      required: (r) => yes(r.peripheralEdema),
      type: 'ENUM',
    },
    pneumonia: {
      type: 'ENUM',
    },
    pneumoniaResolved: {
      required: (r) => yes(r.pneumonia),
      type: 'ENUM',
    },
    pneumothorax: {
      type: 'ENUM',
    },
    pneumothoraxCurrentProblems: {
      required: (r) => (
        r.pneumothoraxMostRecent === TimeFrame.ONE_TO_THREE_YEARS_AGO
        || r.pneumothoraxMostRecent === TimeFrame.LESS_THAN_ONE_YEAR_AGO
      ),
      type: 'ENUM',
    },
    pneumothoraxKnowsCause: {
      required: (r) => (
        r.pneumothoraxMostRecent === TimeFrame.ONE_TO_THREE_YEARS_AGO
        || r.pneumothoraxMostRecent === TimeFrame.LESS_THAN_ONE_YEAR_AGO
      ),
      type: 'ENUM',
    },
    pneumothoraxMostRecent: {
      required: (r) => yes(r.pneumothorax),
      type: 'ENUM',
    },
    pneumothoraxMultiple: {
      required: (r) => (
        r.pneumothoraxMostRecent === TimeFrame.LESS_THAN_ONE_YEAR_AGO
        || (
          r.pneumothoraxMostRecent === TimeFrame.ONE_TO_THREE_YEARS_AGO
          && no(r.pneumothoraxKnowsCause)
        )
      ),
      type: 'ENUM',
    },
    priorRespiratorUse: {
      type: 'ENUM',
    },
    priorRespiratorUseAnxiety: {
      required: (r) => yes(r.priorRespiratorUse),
      type: 'ENUM',
    },
    priorRespiratorUseAnxietyCause: {
      required: (r) => yes(r.priorRespiratorUseAnxietyCurrent),
      type: 'ENUM',
    },
    priorRespiratorUseAnxietyCurrent: {
      required: (r) => yes(r.priorRespiratorUseAnxiety),
      type: 'ENUM',
    },
    priorRespiratorUseAnxietyCurrentlyWorksWithCause: {
      required: (r) => r.priorRespiratorUseAnxietyCause
        === PriorRespiratorUseProblemCause.WORK_ENVIRONMENT,
      type: 'ENUM',
    },
    priorRespiratorUseAnxietyTookOffRespiratorUnsafely: {
      required: (r) => yes(r.priorRespiratorUseAnxietyCurrent),
      type: 'ENUM',
    },
    priorRespiratorUseAnxietyAbleToUseAlternateRespirator: {
      required: (r) => r.priorRespiratorUseAnxietyCause
        === PriorRespiratorUseProblemCause.RESPIRATOR,
      type: 'ENUM',
    },
    priorRespiratorUseEyeIrritation: {
      required: (r) => yes(r.priorRespiratorUse),
      type: 'ENUM',
    },
    priorRespiratorUseEyeIrritationAggravatorsAtWork: {
      required: (r) => r.priorRespiratorUseEyeIrritationCause
        === PriorRespiratorUseProblemCause.WORK_ENVIRONMENT,
      type: 'ENUM',
    },
    priorRespiratorUseEyeIrritationAbleToUseAlternateRespirator: {
      required: (r) => r.priorRespiratorUseEyeIrritationCause
        === PriorRespiratorUseProblemCause.RESPIRATOR,
      type: 'ENUM',
    },
    priorRespiratorUseEyeIrritationCause: {
      required: (r) => yes(r.priorRespiratorUseEyeIrritationCurrent),
      type: 'ENUM',
    },
    priorRespiratorUseEyeIrritationCurrent: {
      required: (r) => yes(r.priorRespiratorUseEyeIrritation),
      type: 'ENUM',
    },
    priorRespiratorUseEyeIrritationImpactsWork: {
      required: (r) => yes(r.priorRespiratorUseEyeIrritationCurrent),
      type: 'ENUM',
    },
    priorRespiratorUseFatigue: {
      required: (r) => yes(r.priorRespiratorUse),
      type: 'ENUM',
    },
    priorRespiratorUseFatigueAdditionalSx: {
      required: (r) => yes(r.priorRespiratorUseFatigueCurrent),
      type: 'ENUM',
    },
    priorRespiratorUseFatigueCausedByHealthProblem: {
      required: (r) => yes(r.priorRespiratorUseFatigueCurrent),
      type: 'ENUM',
    },
    priorRespiratorUseFatigueCausedByPoorFitness: {
      required: (r) => yes(r.priorRespiratorUseFatigueCurrent),
      type: 'ENUM',
    },
    priorRespiratorUseFatigueCurrent: {
      required: (r) => yes(r.priorRespiratorUseFatigue),
      type: 'ENUM',
    },
    priorRespiratorUseFatigueFutureRespiratorUseExpectedNotOk: {
      required: (r) => yes(r.priorRespiratorUseFatigueCurrent),
      type: 'ENUM',
    },
    priorRespiratorUseFatigueImpactsWork: {
      required: (r) => yes(r.priorRespiratorUseFatigueCurrent),
      type: 'ENUM',
    },
    priorRespiratorUseMiscProblems: {
      required: (r) => yes(r.priorRespiratorUse),
      type: 'ENUM',
    },
    priorRespiratorUseMiscProblemsDescription: {
      required: (r) => yes(r.priorRespiratorUseMiscProblems),
      type: 'STRING',
    },
    priorRespiratorUseSkinProblems: {
      required: (r) => yes(r.priorRespiratorUse),
      type: 'ENUM',
    },
    priorRespiratorUseSkinProblemsAbleToUseAlternateRespirator: {
      required: (r) => r.priorRespiratorUseSkinProblemsCause
        === PriorRespiratorUseProblemCause.RESPIRATOR,
      type: 'ENUM',
    },
    priorRespiratorUseSkinProblemsAggravatorsAtWork: {
      required: (r) => r.priorRespiratorUseSkinProblemsCause
        === PriorRespiratorUseProblemCause.WORK_ENVIRONMENT,
      type: 'ENUM',
    },
    priorRespiratorUseSkinProblemsCanUsePpe: {
      required: (r) => (
        yes(r.priorRespiratorUseSkinProblemsAggravatorsAtWork)
        || unknown(r.priorRespiratorUseSkinProblemsAggravatorsAtWork)
      ),
      type: 'ENUM',
    },
    priorRespiratorUseSkinProblemsCause: {
      required: (r) => yes(r.priorRespiratorUseSkinProblemsCurrent),
      type: 'ENUM',
    },
    priorRespiratorUseSkinProblemsCurrent: {
      required: (r) => yes(r.priorRespiratorUseSkinProblems),
      type: 'ENUM',
    },
    rescueInhalerUseAtLeast3PerWeek: {
      required: (r) => (
        yes(r.asthmaMedications)
        || yes(r.sobMedications)
      ),
      type: 'ENUM',
    },
    seizures: {
      type: 'ENUM',
    },
    seizuresControlled: {
      required: (r) => (
        r.seizuresMostRecent === TimeFrame.LESS_THAN_FIVE_YEARS_AGO
        || (
          r.seizuresMostRecent === TimeFrame.FIVE_TO_TEN_YEARS_AGO
          && yes(r.seizuresMultiple)
        )
      ),
      type: 'ENUM',
    },
    seizuresHadAtWork: {
      required: (r, j) => (
        r.seizuresMostRecent === TimeFrame.LESS_THAN_FIVE_YEARS_AGO
        || (
          r.seizuresMostRecent === TimeFrame.FIVE_TO_TEN_YEARS_AGO
          && yes(r.seizuresMultiple)
          && (
            highlyHazardous(j)
            || sarEvaluated(j)
          )
        )
      ),
      type: 'ENUM',
    },
    seizuresMedications: {
      type: 'ENUM',
    },
    seizuresMedicationsAdverseEffects: {
      required: (r) => yes(r.seizuresMedications),
      type: 'ENUM',
    },
    seizuresMostRecent: {
      required: (r) => yes(r.seizures),
      type: 'ENUM',
    },
    seizuresMultiple: {
      required: (r) => (
        r.seizuresMostRecent === TimeFrame.LESS_THAN_FIVE_YEARS_AGO
        || r.seizuresMostRecent === TimeFrame.FIVE_TO_TEN_YEARS_AGO
      ),
      type: 'ENUM',
    },
    seizuresWarningSigns: {
      required: (r, j) => (
        r.seizuresMostRecent === TimeFrame.LESS_THAN_FIVE_YEARS_AGO
        || (
          r.seizuresMostRecent === TimeFrame.FIVE_TO_TEN_YEARS_AGO
          && yes(r.seizuresMultiple)
          && (
            highlyHazardous(j)
            || sarEvaluated(j)
          )
        )
      ),
      type: 'ENUM',
    },
    seizuresWorkAccommodations: {
      required: (r) => yes(r.seizures),
      type: 'ENUM',
    },
    seizuresWorksAlone: {
      required: (r, j) => (
        r.seizuresMostRecent === TimeFrame.LESS_THAN_FIVE_YEARS_AGO
        || (
          r.seizuresMostRecent === TimeFrame.FIVE_TO_TEN_YEARS_AGO
          && yes(r.seizuresMultiple)
          && (
            highlyHazardous(j)
            || sarEvaluated(j)
          )
        )
      ),
      type: 'ENUM',
    },
    silicosis: {
      type: 'ENUM',
    },
    silicosisFutureRespiratorUseExpectedNotOk: {
      required: (r) => (
        yes(r.silicosis)
        && no(r.priorRespiratorUse)
      ),
      type: 'ENUM',
    },
    silicosisHospitalStayLast2Years: {
      required: (r) => (
        r.silicosisSeverity === Severity.MODERATE
        || r.silicosisSeverity === Severity.SEVERE
      ),
      type: 'ENUM',
    },
    silicosisImpactedWorkLast2Years: {
      required: (r) => (
        r.silicosisSeverity === Severity.MODERATE
        || r.silicosisSeverity === Severity.SEVERE
      ),
      type: 'ENUM',
    },
    silicosisMedications: {
      required: (r) => yes(r.silicosis),
      type: 'ENUM',
    },
    silicosisMedicationsAccessibleAtWork: {
      required: (r) => yes(r.silicosisMedications),
      type: 'ENUM',
    },
    silicosisPriorRespiratorUseNotOk: {
      required: (r) => (
        yes(r.silicosis)
        && yes(r.priorRespiratorUse)
      ),
      type: 'ENUM',
    },
    silicosisSeverity: {
      required: (r) => yes(r.silicosis),
      type: 'ENUM',
    },
    silicosisSxDuringAdl: {
      required: (r) => yes(r.silicosis),
      type: 'ENUM',
    },
    silicosisWorkAccommodations: {
      required: (r) => yes(r.silicosis),
      type: 'ENUM',
    },
    silicosisWorseLastYear: {
      required: (r) => yes(r.silicosis),
      type: 'ENUM',
    },
    skippedHeartbeatLastTwoYears: {
      type: 'ENUM',
    },
    smoker: {
      type: 'ENUM',
    },
    smokerTroubleBreathing: {
      required: (r) => yes(r.smoker),
      type: 'ENUM',
    },
    sob: {
      type: 'ENUM',
    },
    sobFutureRespiratorUseExpectedNotOk: {
      required: (r) => (
        (
          yes(r.sob)
          || yes(r.sobInterferingWithWork)
          || yes(r.sobWalkingFastOrIncline)
          || yes(r.sobWalkingOwnPaceLevel)
          || yes(r.sobWalkingWithOthers)
          || yes(r.sobWashingOrDressing)
        )
        && no(r.priorRespiratorUse)
      ),
      type: 'ENUM',
    },
    sobHospitalStayLast2Years: {
      required: (r) => (
        r.sobSeverity === Severity.MODERATE
        || r.sobSeverity === Severity.SEVERE
      ),
      type: 'ENUM',
    },
    sobImpactedWorkLast2Years: {
      required: (r) => (
        r.sobSeverity === Severity.MODERATE
        || r.sobSeverity === Severity.SEVERE
      ),
      type: 'ENUM',
    },
    sobInterferingWithWork: {
      type: 'ENUM',
    },
    sobMedications: {
      required: (r) => (
        yes(r.sob)
        || yes(r.sobInterferingWithWork)
        || yes(r.sobWalkingFastOrIncline)
        || yes(r.sobWalkingOwnPaceLevel)
        || yes(r.sobWalkingWithOthers)
        || yes(r.sobWashingOrDressing)
      ),
      type: 'ENUM',
    },
    sobMedicationsAccessibleAtWork: {
      required: (r) => yes(r.sobMedications),
      type: 'ENUM',
    },
    sobPriorRespiratorUseNotOk: {
      required: (r) => (
        (
          yes(r.sob)
          || yes(r.sobInterferingWithWork)
          || yes(r.sobWalkingFastOrIncline)
          || yes(r.sobWalkingOwnPaceLevel)
          || yes(r.sobWalkingWithOthers)
          || yes(r.sobWashingOrDressing)
        )
        && yes(r.priorRespiratorUse)
      ),
      type: 'ENUM',
    },
    sobSeverity: {
      required: (r) => (
        yes(r.sob)
        || yes(r.sobInterferingWithWork)
        || yes(r.sobWalkingFastOrIncline)
        || yes(r.sobWalkingOwnPaceLevel)
        || yes(r.sobWalkingWithOthers)
        || yes(r.sobWashingOrDressing)
      ),
      type: 'ENUM',
    },
    sobWalkingFastOrIncline: {
      type: 'ENUM',
    },
    sobWalkingOwnPaceLevel: {
      type: 'ENUM',
    },
    sobWalkingWithOthers: {
      type: 'ENUM',
    },
    sobWashingOrDressing: {
      type: 'ENUM',
    },
    sobWorkAccommodations: {
      required: (r) => (
        yes(r.sob)
        || yes(r.sobInterferingWithWork)
        || yes(r.sobWalkingFastOrIncline)
        || yes(r.sobWalkingOwnPaceLevel)
        || yes(r.sobWalkingWithOthers)
        || yes(r.sobWashingOrDressing)
      ),
      type: 'ENUM',
    },
    sobWorseLastYear: {
      required: (r) => (
        yes(r.sob)
        || yes(r.sobInterferingWithWork)
        || yes(r.sobWalkingFastOrIncline)
        || yes(r.sobWalkingOwnPaceLevel)
        || yes(r.sobWalkingWithOthers)
        || yes(r.sobWashingOrDressing)
      ),
      type: 'ENUM',
    },
    speakToProvider: {
      type: 'ENUM',
    },
    stroke: {
      type: 'ENUM',
    },
    strokeLastingEffectsPreventingRespiratorUse: {
      required: (r) => yes(r.stroke),
      type: 'ENUM',
    },
    strokeMostRecentMoreThan2YearsAgo: {
      required: (r) => yes(r.stroke),
      type: 'ENUM',
    },
    strokeSubsequentTia: {
      required: (r) => yes(r.stroke),
      type: 'ENUM',
    },
    strokeWorkAccommodations: {
      required: (r) => yes(r.stroke),
      type: 'ENUM',
    },
    tuberculosis: {
      type: 'ENUM',
    },
    tuberculosisCurrentMedications: {
      required: (r) => yes(r.tuberculosisResolved),
      type: 'ENUM',
    },
    tuberculosisCurrentMedicationsForLatentTbOnly: {
      required: (r) => yes(r.tuberculosisCurrentMedications),
      type: 'ENUM',
    },
    tuberculosisResolved: {
      required: (r) => yes(r.tuberculosis),
      type: 'ENUM',
    },
    visionCorrection: {
      required: (_, j) => fullFaceEvaluated(j),
      type: 'ENUM',
    },
    visionCorrectionCanWearAndSeeAtWork: {
      required: (r) => yes(r.visionCorrection),
      type: 'ENUM',
    },
    visionLoss: {
      required: (_, j) => fullFaceEvaluated(j),
      type: 'ENUM',
    },
    visionLossCanPerformWorkSafely: {
      required: (r) => no(r.visionLossResolved),
      type: 'ENUM',
    },
    visionLossExpectsSuccessfulScbaOrFullFaceUse: {
      required: (r) => (
        (
          no(r.visionLossResolved)
          && no(r.priorRespiratorUse)
        )
        || no(r.visionLossPriorScbaOrFullFaceUse)
      ),
      type: 'ENUM',
    },
    visionLossPriorScbaOrFullFaceUse: {
      required: (r) => (
        no(r.visionLossResolved)
        && yes(r.priorRespiratorUse)
      ),
      type: 'ENUM',
    },
    visionLossPriorScbaOrFullFaceUseSuccessful: {
      required: (r) => yes(r.visionLossPriorScbaOrFullFaceUse),
      type: 'ENUM',
    },
    visionLossResolved: {
      required: (r) => yes(r.visionLoss),
      type: 'ENUM',
    },
    weaknessCanEscapeDanger: {
      required: (r) => yes(r.weaknessInExtremities),
      type: 'ENUM',
    },
    weaknessImpactingWork: {
      required: (r) => yes(r.weaknessInExtremities),
      type: 'ENUM',
    },
    weaknessInExtremities: {
      required: (_, j) => fullFaceEvaluated(j),
      type: 'ENUM',
    },
    weaknessMakingCarryingEquipmentDifficult: {
      required: (r, j) => (
        (
          yes(r.weaknessInExtremities)
          && sarEvaluated(j)
          && no(r.priorRespiratorUse)
        )
        || no(r.weaknessUsedSarLast2Years)
      ),
      type: 'ENUM',
    },
    weaknessPredictsRespiratorWillExacerbate: {
      required: (r) => yes(r.weaknessInExtremities),
      type: 'ENUM',
    },
    weaknessPriorSarUseOkLast2Years: {
      required: (r) => yes(r.weaknessUsedSarLast2Years),
      type: 'ENUM',
    },
    weaknessUsedSarLast2Years: {
      required: (r, j) => (
        yes(r.weaknessInExtremities)
        && sarEvaluated(j)
        && yes(r.priorRespiratorUse)
      ),
      type: 'ENUM',
    },
    weaknessWorkAccommodations: {
      required: (r) => (
        yes(r.weaknessInExtremities)
        || yes(r.difficultyClimbingStairs25Pounds)
      ),
      type: 'ENUM',
    },
    weaknessWorsening: {
      required: (r) => yes(r.weaknessInExtremities),
      type: 'ENUM',
    },
    weight: {
      type: 'NUMBER',
    },
    wheeze: {
      type: 'ENUM',
    },
    wheezeAggravatorsAtWork: {
      required: (r) => (
        yes(r.wheeze)
        || yes(r.wheezeInterferingWithWork)
      ),
      type: 'ENUM',
    },
    wheezeFutureRespiratorUseExpectedNotOk: {
      required: (r) => (
        (
          yes(r.wheeze)
          || yes(r.wheezeInterferingWithWork)
        )
        && no(r.priorRespiratorUse)
      ),
      type: 'ENUM',
    },
    wheezeHospitalStayLast2Years: {
      required: (r) => (
        r.wheezeSeverity === Severity.MODERATE
        || r.wheezeSeverity === Severity.SEVERE
      ),
      type: 'ENUM',
    },
    wheezeImpactedWorkLast2Years: {
      required: (r) => (
        r.wheezeSeverity === Severity.MODERATE
        || r.wheezeSeverity === Severity.SEVERE
      ),
      type: 'ENUM',
    },
    wheezeInterferingWithWork: {
      type: 'ENUM',
    },
    wheezeMedications: {
      required: (r) => (
        yes(r.wheeze)
        || yes(r.wheezeInterferingWithWork)
      ),
      type: 'ENUM',
    },
    wheezeMedicationsAccessibleAtWork: {
      required: (r) => yes(r.wheezeMedications),
      type: 'ENUM',
    },
    wheezePriorRespiratorUseNotOk: {
      required: (r) => (
        (
          yes(r.wheeze)
          || yes(r.wheezeInterferingWithWork)
        )
        && yes(r.priorRespiratorUse)
      ),
      type: 'ENUM',
    },
    wheezeSeverity: {
      required: (r) => (
        yes(r.wheeze)
        || yes(r.wheezeInterferingWithWork)
      ),
      type: 'ENUM',
    },
    wheezeWorkAccommodations: {
      required: (r) => (
        yes(r.wheeze)
        || yes(r.wheezeInterferingWithWork)
      ),
      type: 'ENUM',
    },
    wheezeWorseLastYear: {
      required: (r) => (
        yes(r.wheeze)
        || yes(r.wheezeInterferingWithWork)
      ),
      type: 'ENUM',
    },
  },
};
