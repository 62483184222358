import { Card } from '../../components/Card';
import { InlineRadioField } from '../../components/inputs/InlineRadioField';
import { Language, YesNo } from '../../lib/general';
import { yes } from '../../lib/validation';
import {
  HealthHistoryStageErrors,
  HealthHistoryStageRequirements,
  HealthHistoryStageResponses,
} from '../../types/general';

import { YES_NO_OPTIONS } from './options';

export function EarInjuriesBlock({
  errors,
  reqs,
  responses,
  setResponse,
}: {
  errors: HealthHistoryStageErrors;
  reqs: HealthHistoryStageRequirements;
  responses: HealthHistoryStageResponses;
  setResponse: (
    key: keyof HealthHistoryStageResponses,
  ) => (value: string | string[] | number | null) => void;
}) {
  return (
    <Card title={{
      [Language.ENGLISH]: 'Ear Injuries',
      [Language.SPANISH]: 'Lesiones de Oído',
    }}>
      <InlineRadioField<YesNo>
        error={errors.brokenEardrum}
        label={{
          [Language.ENGLISH]: 'Have you ever had a broken eardrum?',
          [Language.SPANISH]: '¿Alguna vez se ha roto el tímpano?',
        }}
        onChange={setResponse('brokenEardrum')}
        options={YES_NO_OPTIONS}
        value={responses.brokenEardrum}
      />
      {reqs.brokenEardrumResolved && (
        <div className="mt-8">
          <InlineRadioField<YesNo>
            error={errors.brokenEardrumResolved}
            label={{
              [Language.ENGLISH]: 'Has your broken eardrum fully healed or been surgically repaired?',
              [Language.SPANISH]: '¿Su tímpano roto sanó completamente o ha sido reparado quirúrgicamente?',
            }}
            onChange={setResponse('brokenEardrumResolved')}
            options={YES_NO_OPTIONS}
            value={responses.brokenEardrumResolved}
          />
        </div>
      )}
      <div className="mt-8">
        <InlineRadioField<YesNo>
          error={errors.miscEarInjuries}
          label={{
            [Language.ENGLISH]: 'Have you ever had any injury to your ears other than a broken eardrum?',
            [Language.SPANISH]: '¿Ha tenido alguna lesión en los oídos que no sea una rotura de tímpano?',
          }}
          onChange={setResponse('miscEarInjuries')}
          options={YES_NO_OPTIONS}
          value={responses.miscEarInjuries}
        />
      </div>
      {reqs.hearingLossCanPerformWorkSafely && !yes(responses.hearingLoss) && (
        <div className="mt-8">
          <InlineRadioField<YesNo>
            error={errors.hearingLossCanPerformWorkSafely}
            label={{
              [Language.ENGLISH]: 'Can you hear well enough to do your job safely, talk to coworkers, and hear any sounds that warn you of danger?',
              [Language.SPANISH]: '¿Puede oír lo suficientemente bien como para realizar su trabajo con seguridad, hablar con sus compañeros y oír cualquier sonido que le advierta de un peligro?',
            }}
            onChange={setResponse('hearingLossCanPerformWorkSafely')}
            options={YES_NO_OPTIONS}
            value={responses.hearingLossCanPerformWorkSafely}
          />
        </div>
      )}
    </Card>
  );
}
